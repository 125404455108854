<template>
  <div class="container pd col-start-center">
    <headBar
      :title="$store.state.flagJson.type ? '虎鹿瘦喜 新年FLAG' : '超鹿健康资料'"
      left-arrow
      @click-left="newAppBack"
    />
    <img class="banner" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/banner.png" alt="">
    <div class="check-content">
      <h2 class="title f36">现在的你，外表看起来？</h2>
      <p class="f22 mt-8 grey">可参考体型选择哦</p>
      <ul class="check-ul row-start-center van-row--flex">
        <li :class="['check-item--single', 'row-2', 'col-start-center', checkedList.includes(item.code) ? 'checked' : '']" v-for="item in list" :key="item.code" @click="choose(item.code)">
          <img class="check-icon--big" :src="item.img" alt="">
          <div class="col-start-center">
            <p class="f28 dark fw6">{{item.title}}{{item.subtitle}}</p>
            <p class="f22 mt-4 grey" style="margin-bottom: 4px;">{{item.copywriting}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="button-box fixed">
      <div class="cl-button f32 fw5 row-center-center" @click="goNext">下一步（ 4/7 ）</div>
    </div>
  </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod'
import { getList } from './index'
import headBar from '@/components/app/headBar'
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
export default {
  mixins: [userMixin],
  components: {
    headBar,
  },
  data() {
    return {
      list: [],

      checkedList: [],
    }
  },
  computed: {},
  methods: {
    newAppBack,
    choose(code) {
      // 单选
      this.checkedList = []
      this.checkedList.push(code)
      // 多选
      // let index = this.checkedList.indexOf(code)
      // if (index > -1) {
      //   this.checkedList.splice(index, 1)
      // } else {
      //   this.checkedList.push(code)
      // }
    },
    goNext() {
      if (this.checkedList.length === 0) {
        this.$toast({
          message: '请选择',
          duration: 3000
        });
        return
      }
      this.$store.commit('setFlagJson', {newPbf: this.checkedList})
      this.$router.push('page-5')
      this.countPoint('43','311','1479')
    }
  },
  async created() {
    await this.$getAllInfo()

    // sex 1男  0女
    let obj = await getList(this.userId, this.token, 'newPbf', this.$store.state.flagJson.sex[0])

    this.checkedList = obj.codes
    this.list = obj.list
    this.countPoint('43','311','1478')
  },
}
</script>
<style lang="less" scoped>
@import url(./index.less);
.container {
  background-color: #F9FAFB;
}
</style>
